import refsCommon from "../common/refsCommon";
const { menuOverlay, menuToggle, closeMenu } = refsCommon;

document.addEventListener("DOMContentLoaded", () => {
	menuToggle &&
		menuToggle.addEventListener("click", function () {
			if (menuOverlay) {
				menuOverlay.style.display = "flex";
			}
		});

	closeMenu &&
		closeMenu.addEventListener("click", function () {
			if (menuOverlay) {
				menuOverlay.style.display = "none";
			}
		});

	menuOverlay &&
		menuOverlay.addEventListener("click", function (e) {
			if (e.target === menuOverlay) {
				menuOverlay.style.display = "none";
			}
		});
	menuOverlay &&
		menuOverlay.querySelectorAll("a").forEach((el) => {
			el.addEventListener("click", () => (menuOverlay.style.display = "none"));
		});
});
